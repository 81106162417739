document.addEventListener('DOMContentLoaded', function () {
var accessibilityWrapper = document.getElementById("accessibilityWrapper");
var accessibilityButton = document.getElementById("accessibilityButton");
var closeAccessibility = document.getElementById("closeAccessibility");
var closed = true;

accessibilityWrapper.style.setProperty ('right', '100%', 'important');
accessibilityWrapper.style.setProperty ('position', 'fixed', 'important');
accessibilityWrapper.style.setProperty ('background', 'white', 'important');
accessibilityWrapper.style.setProperty ('top', '0px', 'important');

if(accessibilityButton !== null){
    accessibilityButton.onclick = function() {
        if(closed == true){
            console.log('open access')
            accessibilityWrapper.style.setProperty ('right', '0', 'important');
            closed = false; 
        }else{
            console.log('close access')
            accessibilityWrapper.style.setProperty ('right', '100%', 'important');
            closed = true; 
        }
    }
}

if(closeAccessibility !== null){
    closeAccessibility.onclick = function() {
        if(closed == false){
            accessibilityWrapper.style.setProperty ('right', '100%', 'important');
            closed = true;  
        }
    }
}


function clear() {
    alert('clear');
  }
  function clear2() {
    alert('clear2');
  }


});

